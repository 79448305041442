import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import OcStatsService from 'shared/oc-stats-service';
import OlecamsUsersService from 'shared/olecams-users-service';

//libs
import { dateFormat } from 'libs/methods';
import { isEmpty } from 'libs/lodashAlt';

//Component
import Card from 'components/card';
import Pagination from 'components/pagination';
import Source from '../source';
import Form from './form';
import Table from './table';

import IconStatsOC from 'components/icons/statsOlecams';

// style
import '../index.scss';

const DailyStats = (props) => {
  let loading = useSelector((state) => state.loading.loading);
  let stats = useSelector((state) => state.stats.statsDay);
  let statsOCFilters = useSelector((state) => state.stats.statsOCFilters);
  const dispatch = useDispatch();

  const initFilters = {
    ...statsOCFilters,
    method: 'STATS_DAY',
    admin: '',
    afno: statsOCFilters.afno ? statsOCFilters.afno : '',
    affiliate_id: statsOCFilters.affiliate_id ? statsOCFilters.affiliate_id : '',
    date: props.date,
    first_purchase: props.param === 'first' || props.param === 'upsell' ? props.param : '',
    provider: statsOCFilters.provider ? statsOCFilters.provider : '',
    device: statsOCFilters.device ? statsOCFilters.device : '',
    discounts: statsOCFilters.discounts ? statsOCFilters.discounts : 0,
    sitemode: statsOCFilters.sitemode ? statsOCFilters.sitemode : 'ALL',
    whitelabel: statsOCFilters.whitelabel ? statsOCFilters.whitelabel : '',
    fidelity: statsOCFilters.fidelity ? statsOCFilters.fidelity : '',
    status: statsOCFilters.status ? statsOCFilters.status : '1',
    attempt: statsOCFilters.attempt ?? '',
    pager: {
      page: '1',
      items: '150',
    },
  };

  const [filters, setFilters] = useState(initFilters);
  const [localFilters, setLocalFilters] = useState(initFilters);
  const [date, setDate] = useState(null);

  const getData = useCallback(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
    OcStatsService.getStats(filters)
      .then((responseData) => {
        dispatch({ type: 'SET_STATS', statsDay: responseData.message, statsOCFilters: filters });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  }, [dispatch, filters]);

  useEffect(getData, [getData]);

  const getStats = (e) => {
    e.preventDefault();
    setFilters(localFilters);
    //getStatsDay(filters, auth.token, dispatch);
  };

  const getStatDetail = (id, provider) => {
    dispatch({ type: 'SET_LOADING', loading: true });
    let _filters = {
      ...filters,
      method: 'STATS_DETAIL',
      id: id,
      provider: provider,
    };

    OcStatsService.getStats(_filters)
      .then((responseData) => {
        dispatch({ type: 'SET_STATS_DETALL', statsDetall: responseData.message, statsOCFilters: _filters });
        dispatch({ type: 'MODAL', statsDetall: true });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
    //getDetall(_filters, auth.token, dispatch);
  };

  const getUserInfo = (id) => {
    dispatch({ type: 'SET_LOADING', loading: true });

    OlecamsUsersService.adminGet(id)
      .then((responseData) => {
        dispatch({ type: 'SET_STATS_DETALL', statsDetall: responseData.message.data });
        dispatch({ type: 'MODAL', statsDetall: true });
      })
      .finally(() => dispatch({ type: 'SET_LOADING', loading: false }));
  };

  const handleChangeDate = (date) => {
    setLocalFilters({
      ...localFilters,
      date: date ? dateFormat(date) : '',
    });
    setDate(date);
  };

  const handleOnChange = (e) => {
    const { value, name, checked } = e.target;
    let _value = name === 'discounts' ? checked : value;

    if (name === 'payment_type') {
      setLocalFilters({
        ...localFilters,
        [name]: _value,
        provider: '',
      });
    } else {
      setLocalFilters({
        ...localFilters,
        [name]: _value,
      });
    }
  };

  // PAGINATION
  const paginate = (e) => {
    let _filters = {
      ...localFilters,
      pager: {
        ...localFilters.pager,
        page: e.target.value,
      },
    };
    setLocalFilters(_filters);
    setFilters(_filters);
  };

  const handleChangeNumberOfColum = (e) => {
    let _filters = {
      ...localFilters,
      pager: {
        ...localFilters.pager,
        items: e.target.value,
        page: 1,
      },
    };
    setLocalFilters(_filters);
    setFilters(_filters);
  };

  const nextPage = (page) => {
    page++;
    if (page <= stats.pager.pages) {
      let _filters = {
        ...localFilters,
        pager: { ...localFilters.pager, page: page },
      };
      setLocalFilters(_filters);
      setFilters(_filters);
    }
  };

  const prevPage = (page) => {
    page--;
    if (page > 0) {
      let _filters = {
        ...localFilters,
        pager: { ...localFilters.pager, page: page },
      };
      setLocalFilters(_filters);
      setFilters(_filters);
    }
  };
  // END PAGINATION

  //if (loading) return <Loading />;

  return (
    <div className="stats">
      {!isEmpty(stats) && (
        <div className="statsolecams-content">
          <Card icon={<IconStatsOC color="#4b5056" />} title={<FormattedMessage id="statistics-resum-title-olecams" />}>
            <div className="stats-ol">
              <Form
                date={date}
                handleChangeDate={handleChangeDate}
                handleOnChange={handleOnChange}
                getStats={getStats}
                filters={localFilters}
              />
            </div>
          </Card>
          {!loading && (
            <div className="table-content">
              <Table stats={stats} getStatDetall={getStatDetail} getUserInfo={getUserInfo} />
              {/* paginte content */}
              <Pagination
                handleChangeNumberOfColum={handleChangeNumberOfColum}
                pager={stats.pager}
                paginate={paginate}
                prevPage={prevPage}
                nextPage={nextPage}
                total={stats.summary.count}
              />
            </div>
          )}
          <Source />
        </div>
      )}
    </div>
  );
};

export default DailyStats;
