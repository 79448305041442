import { useIntl } from 'react-intl';

const SelectFidelity = ({ value, handleChange }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="form-group">
      <label>Tipo de Fidelidad</label>
      <select className="form-control" name="fidelity" onChange={handleChange} value={value}>
        <option value="">{formatMessage({ id: 'statistics-form-select-all' })}</option>
        <option value="0">Sin fidelidad</option>
        <option value="1">Fidelidad Anónimo</option>
      </select>
    </div>
  );
};

export default SelectFidelity;
